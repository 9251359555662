import React, { useContext, useEffect, useState } from "react";
import { MenuContaxt } from "./Contaxt";
import "../assest/css/ExcelUploader.css"; // Import your CSS file
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const ExcelUploader = () => {
  const { wineData, setWineData, handleFileChange, handleDone, setOutletName, check } = useContext(MenuContaxt);
  const [selectedStore, setSelectedStore] = useState(''); // State for the selected store
console.log("selectedStore------------------>",selectedStore)
  const navigate = useNavigate();
  const location = useLocation();
  const { STORE_NAME } = location.state?.[0] || {}; // Destructure the data


  const [searchParams] = useSearchParams();
  const outletName = searchParams.get('outlet_name')
console.log("outletNames-----------ExcelUploader------------>",outletName)

  useEffect(()=>{

    setOutletName(selectedStore)

  },[selectedStore])

  const ClearData = () => {
    setWineData({});
    localStorage.clear();
  };

  // Function to handle the change when a store is selected
  const handleStoreSelect = (event) => {
    setSelectedStore(event.target.value); // Update the selected store
    console.log("Selected Store:", event.target.value); // Log the selected store (optional)
  };

  return (
    <div className="excel-uploader-container">
      <h2 className="upload-title">Upload Excel File</h2>

      <button onClick={check}>Check id </button>

      {STORE_NAME && STORE_NAME.length > 0 && (
        <div>
          <label htmlFor="storeSelect">Select Outlet:</label>
          <select id="storeSelect" value={selectedStore} onChange={handleStoreSelect}>
            <option value="">Select a store</option> {/* Default option */}
            {STORE_NAME.map((storeName, index) => (
              <option key={index} value={storeName}>
                {storeName}
              </option>
            ))}
          </select>
        </div>
      )}

      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileChange}
        className="file-input"
      />

      <div className="button-container">
        {/* <button className="clear-button" onClick={ClearData}>
          Clear Data
        </button> */}
        <button className="done-button" onClick={handleDone}>
          Done
        </button>
      </div>
    </div>
  );
};

export default ExcelUploader;

