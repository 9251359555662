// import React, { useContext } from "react";
// import "../assest/css/MenuButtons.css";
// import { MenuContaxt } from "./Contaxt";

// const MenuButtons = () => {
//   const { wineData, selectedCategory, setSelectedCategory } = useContext(MenuContaxt);

//   // Combine all wine items from all categories into one array
//   const allWines = Object.values(wineData).flat();

//   return (
//     <div className="menu-card">
//       <div className="menu-buttons">
//         {/* Add the "All" button */}
//         <button
//           onClick={() => setSelectedCategory('all')}
//           className={`menu-button ${selectedCategory === 'all' ? 'active' : ''}`}
//         >
//           All Wines
//         </button>

//         {Object.keys(wineData).map((category) => (
//           <button
//             key={category}
//             onClick={() => setSelectedCategory(category)}
//             className={`menu-button ${selectedCategory === category ? 'active' : ''}`}
//           >
//             {category.charAt(0).toUpperCase() + category.slice(1)} Wine
//           </button>
//         ))}
//       </div>

//       <div className="wine-details fade-in">
//         {selectedCategory === 'all'
//           ? allWines.map((wine, index) => (
//               <div key={index} className="wine-item">
//                 <div className="wine-name">{wine.brandname}</div>
//                 <div className="wine-price">
//                   {Object.keys(wine).map((key, i) => (
//                     key !== 'brandname' && ( // Skip 'brandname' and display other keys
//                       <div key={i}>
//                         <span className={`wine-${key}`}>
//                           {wine[key] !== 0 ? `${wine[key]} Rs (${key})` : ''}
//                         </span>
//                       </div>
//                     )
//                   ))}
//                 </div>
//               </div>
//             ))
//           : wineData[selectedCategory]?.map((wine, index) => (
//               <div key={index} className="wine-item">
//                 <div className="wine-name">{wine.brandname}</div>
//                 <div className="wine-price">
//                   {Object.keys(wine).map((key, i) => (
//                     key !== 'brandname' && ( // Skip 'brandname' and display other keys
//                       <div key={i}>
//                         <span className={`wine-${key}`}>
//                           {wine[key] !== 0 ? `${wine[key]} Rs (${key})` : ''}
//                         </span>
//                       </div>
//                     )
//                   ))}
//                 </div>
//               </div>
//             ))}
//       </div>
//     </div>
//   );
// };

// export default MenuButtons;



import React, { useContext } from "react";
import "../assest/css/MenuButtons.css";
import { MenuContaxt } from "./Contaxt";

const MenuButtons = () => {
    const { wineData, selectedCategory, setSelectedCategory } = useContext(MenuContaxt);

    // Combine all wine items from all categories into one array
    const allWines = Object.values(wineData).flat();

    // Updated helper function to add spaces between known word parts, like "Wine" or "Port"
    const formatCategory = (category) => {
        return category
            .replace(/wine/g, ' Wine') // Add space before "Wine"
            .replace(/port/g, 'Port ') // Handle "Port Wine" explicitly
            .replace(/(^[a-z])/, (match) => match.toUpperCase()); // Capitalize the first letter
    };

    return (
        <div className="menu-card">
            <div className="menu-buttons">
                {/* Add the "All" button */}
                <button
                    onClick={() => setSelectedCategory('all')}
                    className={`menu-button ${selectedCategory === 'all' ? 'active' : ''}`}
                >
                    All Wines
                </button>

                {Object.keys(wineData).map((category) => {
                    const formattedCategory = formatCategory(category);
                    console.log("formattedCategory------->", formattedCategory);

                    return (
                        <button
                            key={category}
                            onClick={() => setSelectedCategory(category)}
                            className={`menu-button ${selectedCategory === category ? 'active' : ''}`}
                        >
                            {formattedCategory}
                        </button>
                    );
                })}
            </div>

            <div className="wine-details fade-in">
                {(selectedCategory === 'all' ? allWines : wineData[selectedCategory] || []).map((wine, index) => (
                    <div key={index} className="wine-item">
                        <div className="wine-name">{wine.brandname}</div>
                        <div className="wine-price">
                            {Object.keys(wine)
                                .filter(key => key !== 'brandname')
                                .sort((a, b) => {
                                    const mlA = parseInt(a.replace(/\D/g, '')) || 0;
                                    const mlB = parseInt(b.replace(/\D/g, '')) || 0;
                                    return mlB - mlA; // Sort by ml size descending
                                })
                                .map((key, i) => (
                                    wine[key] !== 0 && (
                                        <div key={i}>
                                            <span className={`wine-${key}`}>
                                                {`${wine[key]} Rs (${key})`}
                                            </span>
                                        </div>
                                    )
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MenuButtons;
