import React from 'react'
import Header from './Header'
import MenuButtons from './MenuButtons'
import Footer from './Footer'
import { useSearchParams } from 'react-router-dom'


function Index() {

  console.log("--------------test------------------------------Index")
  const [searchParams] = useSearchParams();
  const outletName = searchParams.get('outlet_name')
console.log("outletNames-----------Index------------>",outletName)

  return (
    <div>

      <Header />
      <MenuButtons />
      <Footer />
    </div>
  )
}

export default Index

