"use client";
import { TextGenerateEffect } from "../textGenerateEffect/TextGenerate2";

const words = `“Wine, the bridge that connects cultures and brings people together.”`;

export function TextGenerateEffectDemo2() {
  return <TextGenerateEffect words={words} />;
}


