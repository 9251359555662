"use client";
import { TextGenerateEffect } from "../textGenerateEffect/TextGenerate";

const words = `Let’s Beautify Your Life with Ambi Wine's`;

export function TextGenerateEffectDemo() {
  return <TextGenerateEffect words={words} />;
}


