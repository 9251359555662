import React from 'react'
import ExcelUploader from './ExcelUploader'
import MenuButtonsLogin from './MenuButtonsLogin'

function IndexLogin() {
  return (
    <div>
         <ExcelUploader />
      <MenuButtonsLogin />
    </div>
  )
}

export default IndexLogin