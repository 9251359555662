import React, { useContext } from "react";
import "../assest/css/MenuButtons.css";
import { MenuContaxt } from "./Contaxt";

const MenuButtonsLogin = () => {
    const { wineDataLogin, selectedCategory, setSelectedCategory, deleteSheet } = useContext(MenuContaxt);

    // Combine all wine items from all categories into one array
    const allWines = Object.values(wineDataLogin).flat();

    console.log("wineDataLogin----s---MenuButtonsLogin-->",wineDataLogin)

    return (
        <div className="menu-card">
        <div className="menu-buttons">
            {/* Add the "All" button */}
            <button
                onClick={() => setSelectedCategory('all')}
                className={`menu-button ${selectedCategory === 'all' ? 'active' : ''}`}
            >
                All Wines
            </button>

            {Object.keys(wineDataLogin).map((category) => (
                <button
                    key={category}
                    onClick={() => setSelectedCategory(category)}
                    className={`menu-button ${selectedCategory === category ? 'active' : ''}`}
                >
                    {category.charAt(0).toUpperCase() + category.slice(1)} 
                </button>
            ))}
        </div>

        <div className="wine-details fade-in">
            {(selectedCategory === 'all' ? allWines : wineDataLogin[selectedCategory] || []).map((wine, index) => (
                <div key={index} className="wine-item">
                    <div className="wine-name">{wine.brandname}</div>
                    <div className="wine-price">
                        {Object.keys(wine)
                            .filter(key => key !== 'brandname')
                            .sort((a, b) => {
                                const mlA = parseInt(a.replace(/\D/g, '')) || 0;
                                const mlB = parseInt(b.replace(/\D/g, '')) || 0;
                                return mlB - mlA; // Sort by ml size descending
                            })
                            .map((key, i) => (
                                wine[key] !== 0 && (
                                    <div key={i}>
                                        <span className={`wine-${key}`}>
                                            {`${wine[key]} Rs (${key})`}
                                        </span>
                                    </div>
                                )
                            ))}
                    </div>
                </div>
            ))}
        </div>
    </div>
    );
};

export default MenuButtonsLogin;
