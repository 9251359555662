import React, { useContext } from "react";
import "../assest/css/Header.css";
import logo from '../assest/images/Logo.png';
import { TextGenerateEffectDemo } from "../components/ui/textGenerateEffect/TextGenerateComponent";
import { TextGenerateEffectDemo2 } from "../components/ui/textGenerateEffect/TextGenerateComponent2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MenuContaxt } from "./Contaxt";

const Header = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const outletName = searchParams.get('outlet_name')
console.log("outletNames-----------Header------------>",outletName)

const handleLogin = () => {
  // Navigate to the login page and pass the outlet_name as a query parameter
  navigate(`/login?outlet_name=${encodeURIComponent(outletName)}`);
};



  return (
    <header className="header">
      <button onClick={() => handleLogin()} className="login-button-home">
        Login
      </button>

      <div className="logo-text-container">
        <img
          src={logo}
          alt="Logo"
          className="flip-animation"
          style={{ height: 200, width: 200, borderRadius: 50 }}
        />
        <div className="text-effect-container">
          <TextGenerateEffectDemo />
          <TextGenerateEffectDemo2 />
        </div>
      </div>
    </header>
  );
};

export default Header;
