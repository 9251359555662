import React from "react";
import "./App.css";
import Contaxt from "./screens/Contaxt";
import LoginScreen from "./screens/LoginScreen";
import Index from "./screens/Index";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import IndexLogin from "./screens/IndexLogin";


function App() {



  return (
    <div className="app">
     <BrowserRouter>
    <Contaxt>
     <Routes>
      <Route path="/" element={<Index/> }/>
      <Route path="/login" element={<LoginScreen/> }/>
      <Route path="/uploading" element={<IndexLogin/> }/>
     </Routes>
    </Contaxt>
     </BrowserRouter>
    </div>
  );
}

export default App;













