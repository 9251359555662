



// import React, { createContext, useEffect, useState } from 'react';
// import * as XLSX from "xlsx";
// import { addDoc, collection, getDocs } from 'firebase/firestore'; // Import Firestore functions
// import { fireDb } from '../firebase/FirebaseConfig'; // Import Firebase configuration
// import { useLocation } from 'react-router-dom';

// export const MenuContaxt = createContext();

// function Contaxt({ children }) {
//     const location = useLocation();

//     // Create a URLSearchParams object from the query string
//     const searchParams = new URLSearchParams(location.search);

//     // Get the query parameters using the get method
//     const outlet_name = searchParams.get('outlet_name');
//     console.log("Outlet Name:", outlet_name); // Debugging log
    
//     const [outletName,setOutletName] = useState(outlet_name)
//     console.log("outletName:--------------", outletName); // Debugging log

//     const [selectedCategory, setSelectedCategory] = useState('red');
//     const [wineData, setWineData] = useState({});
// console.log("wineData--------->",wineData)
//     // Fetch wine data from Firestore when the component mounts
//     useEffect(() => {
//         const fetchWineData = async () => {
//             try {
//                 const querySnapshot = await getDocs(collection(fireDb, 'wineData'));
//                 const fetchedData = {};

//                 querySnapshot.forEach(doc => {
//                     const data = doc.data();
//                     // Check if the outlet matches the outlet_name from query params
//                     if (data.outlet === outletName) {
//                         fetchedData[data.category] = data.wines;
//                     }
//                 });

//                 setWineData(fetchedData);
//             } catch (err) {
//                 console.error('Error fetching wine data:', err);
//             }
//         };

//         fetchWineData();
//     }, [outletName]); // Include outlet_name as a dependency

//     // Handle file change and update wineData with the imported data
//     const handleFileChange = async (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             const reader = new FileReader();

//             reader.onload = (e) => {
//                 const binaryStr = e.target.result;
//                 const workbook = XLSX.read(binaryStr, { type: "binary" });

//                 // Read the first sheet
//                 const firstSheetName = workbook.SheetNames[0];
//                 const worksheet = workbook.Sheets[firstSheetName];

//                 // Convert sheet data to JSON
//                 const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

//                 // Assume the first row contains headers (category, ml750, ml375, etc.)
//                 const headers = jsonData[1];  // row 1 in Excel (index 1 in JSON) contains headers

//                 // Extract data starting from row 2 onwards (since row 1 contains headers)
//                 const formattedWines = jsonData.slice(2).map(row => {
//                     const wineObj = {};
//                     headers.forEach((header, index) => {
//                         wineObj[header.toLowerCase().replace(/\s+/g, '')] = row[index];  // Convert header to lowercase, remove spaces
//                     });
//                     return wineObj;
//                 });

//                 // Assume the first row (jsonData[0]) contains the category (e.g., 'Fortified Wine')
//                 const category = jsonData[0][0];

//                 // Update wineData state with the new category and wines
//                 const updatedWineData = {
//                     ...wineData,
//                     [category.toLowerCase().replace(/\s+/g, '')]: formattedWines,
//                 };
//                 setWineData(updatedWineData);

//                 // Save data to Firestore (uploading wine data)
//                 saveDataToFirestore(category.toLowerCase().replace(/\s+/g, ''), formattedWines);
//             };

//             reader.readAsBinaryString(file);
//         }
//     };

//     // Function to save the parsed data to Firebase Firestore
//     const saveDataToFirestore = async (category, wines) => {
//         try {
//             const collectionRef = collection(fireDb, 'wineData'); // Create a reference to 'wineData' collection
//             const dataToSave = {
//                 category,
//                 wines,
//                 outlet: outletName // Include outlet name
//             };
//             await addDoc(collectionRef, dataToSave); // Add the wine data to Firestore
//             console.log('Data saved to Firestore');
//         } catch (err) {
//             console.error('Error saving data to Firestore:', err);
//         }
//     };

//     return (
//         <MenuContaxt.Provider value={{ wineData, setWineData, selectedCategory, setSelectedCategory, handleFileChange }}>
//             {children}
//         </MenuContaxt.Provider>
//     );
// }

// export default Contaxt;











import React, { createContext, useEffect, useState } from 'react';
import * as XLSX from "xlsx";
import { addDoc, collection, getDocs, query, setDoc, where } from 'firebase/firestore'; // Import Firestore functions
import { fireDb } from '../firebase/FirebaseConfig'; // Import Firebase configuration
import { useLocation, useNavigate } from 'react-router-dom';

export const MenuContaxt = createContext();

function Contaxt({ children }) {
    console.log("--------------test------------------------------Contaxt")

    const navigation = useNavigate()

    const location = useLocation();

    // Create a URLSearchParams object from the query string
    const searchParams = new URLSearchParams(location.search);

    // Get the query parameters using the get method
    const outlet_name = searchParams.get('outlet_name');
    console.log("Outlet Name:-------------->", outlet_name); // Debugging log
    
    const [outletName,setOutletName] = useState('')
    
    console.log("outletName:----------s----", outletName); // Debugging log

    const [selectedCategory, setSelectedCategory] = useState('all');
    const [wineData, setWineData] = useState({});
    const [wineDataLogin, setWineDataLogin] = useState({});
    
    const [category, setCategory] = useState([]);
    const [formattedWines, setFormattedWines] = useState('');

    useEffect(()=>{
        setOutletName(outlet_name)
    },[])
    
    
    console.log("wineDataLogin-----s->",wineDataLogin)
console.log("category------ss--->",category)
    // Fetch wine data from Firestore when the component mounts
    useEffect(() => {
        fetchWineData();
    }, [outletName]); // Include outlet_name as a dependency
    
    const fetchWineData = async () => {
        try {
            const querySnapshot = await getDocs(collection(fireDb, 'wineData'));
            const fetchedData = {};

            querySnapshot.forEach(doc => {
                const data = doc.data();
                // Check if the outlet matches the outlet_name from query params
                if (data.outlet === outletName) {
                    fetchedData[data.category] = data.wines;
                }
            });

            console.log("outletName-------s--->",outletName)
            console.log("fetchedData-------s--->",fetchedData)

            setWineData(fetchedData);
            setWineDataLogin(fetchedData)
        } catch (err) {
            console.error('Error fetching wine data:', err);
        }
    };


    // useEffect(()=>{
    //    if(category != '' && formattedWines != ''){
    //     saveDataToFirestore(category,formattedWines)
    //    }
    // },[category,formattedWines])

    // Handle file change and update wineData with the imported data
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const binaryStr = e.target.result;
                const workbook = XLSX.read(binaryStr, { type: "binary" });

                // Read the first sheet
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];

                // Convert sheet data to JSON
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                // Assume the first row contains headers (category, ml750, ml375, etc.)
                const headers = jsonData[1];  // row 1 in Excel (index 1 in JSON) contains headers

                // Extract data starting from row 2 onwards (since row 1 contains headers)
                const formattedWines = jsonData.slice(2).map(row => {
                    const wineObj = {};
                    headers.forEach((header, index) => {
                        wineObj[header.toLowerCase().replace(/\s+/g, '')] = row[index];  // Convert header to lowercase, remove spaces
                    });
                    return wineObj;
                });

                // Assume the first row (jsonData[0]) contains the category (e.g., 'Fortified Wine')
                const category = jsonData[0][0];

                console.log("category-------->",category)
                
                // Update wineData state with the new category and wines
                const updatedWineData = {
                    ...wineDataLogin,
                    [category.toLowerCase().replace(/\s+/g, '')]: formattedWines,
                };
                console.log("updatedWineData-------->",updatedWineData)
                console.log("formattedWines-------->",formattedWines)
                setWineDataLogin(updatedWineData);

                // Save data to Firestore (uploading wine data)
                // saveDataToFirestore(category.toLowerCase().replace(/\s+/g, ''), formattedWines);


                // setCategory(category.toLowerCase().replace(/\s+/g, ''))
                // setFormattedWines(formattedWines)

                   // Update the category state by appending the new category and wines
                   setCategory(prevCategory => [
                    ...prevCategory,
                    { category: category.toLowerCase().replace(/\s+/g, ''), wines: formattedWines }
                ]);
                
                // setCategory([...category, {a:category.toLowerCase().replace(/\s+/g, ''),b:formattedWines}])
                // console.log("forstorage-------------------------------->",{a:category.toLowerCase().replace(/\s+/g, ''),b:formattedWines})
                // const [category, setCategory] = useState('');
                // const [formattedWines, setFormattedWines] = useState('');

                console.log("category-----------after------->",category.toLowerCase().replace(/\s+/g, ''))
                console.log("formattedWines-----------after------->",formattedWines)

            };

            reader.readAsBinaryString(file);
        }
    };

    // // Function to save the parsed data to Firebase Firestore
    // const saveDataToFirestore = async () => {
    //     try {
    //         const collectionRef = collection(fireDb, 'wineData'); // Reference to the 'wineData' collection
            
    //         // Loop through each category and save to Firestore
    //         for (const cat of category) {

    //             const dataToSave = {
    //                 category: cat.category,
    //                 wines: cat.wines,
    //                 outlet: outletName // Include outlet name
    //             };
    //             console.log("cat--------------->",cat)
    //             console.log("dataToSave--------------->",dataToSave)
    //             await addDoc(collectionRef, dataToSave); // Add the wine data to Firestore
    //             console.log(`Data for category ${cat.category} saved to Firestore`);
    //         }

    //         setCategory([]); // Clear the category array after saving
    //         fetchWineData()
            
    //     } catch (err) {
    //         console.error('Error saving data to Firestore:', err);
    //     }
    // };

    const saveDataToFirestore = async () => {
        try {
            const collectionRef = collection(fireDb, 'wineData'); // Reference to the 'wineData' collection
    
            // Loop through each category and save to Firestore
            for (const cat of category) {
                // Create a query to check if the category and outlet already exist
                const q = query(
                    collectionRef,
                    where('category', '==', cat.category),
                    where('outlet', '==', outletName) // Include the outlet name in the query
                );
    
                const querySnapshot = await getDocs(q);
    
                const dataToSave = {
                    category: cat.category,
                    wines: cat.wines,
                    outlet: outletName // Include outlet name
                };
    
                if (!querySnapshot.empty) {
                    // Document exists, update it
                    const existingDoc = querySnapshot.docs[0]; // Get the first matching document
                    await setDoc(existingDoc.ref, dataToSave, { merge: true }); // Update the document with merge
                    console.log(`Data for category ${cat.category} updated in Firestore`);
                } else {
                    // Document does not exist, create it
                    await addDoc(collectionRef, dataToSave); // Add the wine data to Firestore
                    console.log(`Data for category ${cat.category} saved to Firestore`);
                }
            }
    
            setCategory([]); // Clear the category array after saving
            fetchWineData(); // Refresh the data after saving/updating
    
        } catch (err) {
            console.error('Error saving data to Firestore:', err);
        }
    };

    const handleDone = () => {
        // Execute the save function when user clicks "Done"
        console.log('Done action executed');
        if (category.length > 0) {
            saveDataToFirestore(); // Save all categories to Firestore
       
        }
        // navigation('/', { replace: true });

        console.log("outletName---navigation---->",outletName)

        navigation(`/?outlet_name=${encodeURIComponent(outletName)}`, { replace: true });
    };


    // const deleteSheet = (category) => {
    //     console.log("wineDataLogin before delete:-------->", wineDataLogin);
    //     console.log("Deleting category:-------->", category);

    //     // Create a copy of wineDataLogin without the deleted category
    //     const updatedWineDataLogin = { ...wineDataLogin };
    //     delete updatedWineDataLogin[category]; // Remove the category

    //     // Update the state with the modified object
    //     setWineDataLogin(updatedWineDataLogin);

    //      // Also update the category state by filtering out the deleted category
    // setCategory(prevCategory => prevCategory.filter(cat => cat.category !== category));

    //     console.log("wineDataLogin after delete:---------------------------->", updatedWineDataLogin);
    // };


    const deleteSheet = (cat) => {
        console.log("wineDataLogin before delete:-------->", wineDataLogin);
        console.log("Deleting category:-------->", cat);

        const newArray = Object.keys(wineDataLogin).map(key => ({
            [key]: wineDataLogin[key]
          }));
          
          console.log("newArray=-------->",newArray);


          const newData = newArray.map(item => {
            const [category, wines] = Object.entries(item)[0]; // Extract key and value
            return { category, wines };
          });
          
          console.log("newData=-------->",newData);


          const newcategory = newData.filter((item)=> item.category != cat)
console.log("newcategory-------------->",newcategory)


const {[cat]: removedValue, ...rest} = wineDataLogin

console.log("removedValue-------check---->",removedValue)
console.log("rest--------check--->",rest)
setWineDataLogin(rest)

setCategory(newcategory)





        // console.log("wineDataLogin after delete:---------------------------->", updatedWineDataLogin);

        // const newData = updatedWineDataLogin.map(item => {
        //     const [category, wines] = Object.entries(item)[0]; // Extract key and value
        //     return { category, wines };
        //   });

        //   console.log("newData---------------------->",newData)
          
    };



    const check = async () => {
        console.log("check");
    
        // Define the collection name and values for filtering
        const collectionName = 'wineData';
        const categoryValue = 'whitewine';  // Replace with the specific category you're searching for
        const outlatesValue = 'AMBI THE WINE SHOP GONDIYA ROAD BALAGHAT';  // Replace with the specific outlet you're searching for
    
        // Reference to the collection
        const collectionRef = collection(fireDb, collectionName);
    
        // Query the collection for documents matching both category and outlet
        const q = query(
            collectionRef,
            where('category', '==', categoryValue),
            where('outlet', '==', outlatesValue)
        );
    
        try {
            // Execute the query and get matching documents
            const querySnapshot = await getDocs(q);
    
            // Check if any documents matched the query
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    // Dynamically get the document ID for matching documents
                    console.log("Matching Document ID:", doc.id);
                    console.log("Matching Document Data:", doc.data());
                });
            } else {
                console.log("No matching documents found.");
            }
        } catch (error) {
            console.error("Error fetching documents: ", error);
        }
    };
    



    return (
        <MenuContaxt.Provider value={{ wineData, setWineData, wineDataLogin, outletName, setOutletName, selectedCategory, setSelectedCategory, handleFileChange, handleDone, deleteSheet, check }}>
            {children}
        </MenuContaxt.Provider>
    );
}

export default Contaxt;

